import React from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import AOS CSS

AOS.init();

const testimonials = () => {
    return (
      <section id="testimonials" className="testimonials section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Testimonials</h2>
            <p>
              <strong>Listed here under are some of the testimonials provided by
              different scholars about me.</strong>
            </p>
          </div>

          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item" data-aos="fade-up">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    <i className="d-big icon_quotations"></i>
                    As the Founder and Senior Web Developer at Evangadi Tech, I
                    had the distinct privilege of mentoring and guiding an
                    exceptional individual in the art of full stack web
                    development. Through comprehensive hands-on experiences, Mr.
                    Bikila Negasa exhibited a remarkable aptitude for absorbing
                    knowledge and applying it effectively. With a strong
                    foundation in full stack web development, he displayed a
                    keen understanding of various programming languages,
                    frameworks, and technologies. His dedication, persistence,
                    and collaborative spirit have been instrumental in fostering
                    a vibrant learning environment within the Evangadi Tech
                    community, shaping a promising future in the dynamic realm
                    of web development.
                    <i className="d-big icon_quotations"></i>
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="img/testimonials/testimonials-1.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Adugna Bekele</h3>
                  <h4>Evangadi Tech Founder &amp; Senior Web Developer</h4>
                </div>
              </div>

              {/*End testimonial item*/}

              <div className="swiper-slide">
                <div
                  className="testimonial-item"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    <i className="d-big icon_quotations"></i>
                    In my capacity as a Senior Full-stack Web Development
                    Instructor at Evangadi Tech, I had the pleasure of mentoring
                    Mr. Bikila Negasa who exhibited a strong passion and
                    dedication for web development. During our time together at
                    Evangadi Tech, he showcased a remarkable ability to grasp
                    complex concepts swiftly and translate them into functional,
                    well-designed web applications. His enthusiasm for learning
                    and diligent work ethic were truly commendable, allowing him
                    to excel in various programming languages, frameworks, and
                    methodologies essential for full stack development. His
                    proactive engagement within the learning community at
                    Evangadi Tech not only enriched his own understanding but
                    also inspired fellow learners. I have no doubt that his
                    potential in the field of web development is boundless, and
                    I am proud to have been a part of his educational journey.
                    <i className="d-big icon_quotations"></i>
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="img/testimonials/testimonials-2.png"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Biruk Kebede</h3>
                  <h4>
                    Senior Full-stack Web Development Instructor at Evangadi
                    Tech
                  </h4>
                </div>
              </div>
              {/* End testimonial item */}

              <div className="swiper-slide">
                <div
                  className="testimonial-item"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    <i className="d-big icon_quotations"></i>
                    As a mentor in Full-stack Web Development at Evangadi Tech,
                    I had the privilege of closely monitoring Mr. Bikila
                    Negasa's progress and growth. His dedication to honing his
                    skills in web development was evident through his continuous
                    engagement with the projects and assignments. I consistently
                    provided feedback, insightful comments, and constructive
                    directions on their homework assignments, ensuring he
                    grasped the core concepts and techniques essential for a
                    full-stack developer. It was inspiring to witness his
                    determination to learn and improve, always eager to
                    implement suggestions and elevate the quality of his work.
                    His proactive approach to learning and his receptiveness to
                    guidance assured me of his potential to thrive in the
                    dynamic field of web development, and it was a pleasure to
                    support him on this educational journey at Evangadi Tech.
                    <i className="d-big icon_quotations"></i>
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="img/testimonials/testimonials-3.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Daniel Kebede</h3>
                  <h4>
                    Instructor of Full-stack Web Development at Evangadi Tech
                  </h4>
                </div>
              </div>
              {/*End testimonial item*/}

              <div className="swiper-slide">
                <div
                  className="testimonial-item"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    <i className="d-big icon_quotations"></i>
                    It was a pleasure to work closely with Mr. Bikila Negasa
                    during his journey in mastering full stack web development
                    at Evangadi Tech, where I provided guidance and
                    encouragement. His dedication and relentless pursuit of
                    excellence were evident in his consistent efforts to
                    overcome challenges and grasp complex concepts. His ability
                    to absorb knowledge and implement it in his projects was
                    truly commendable. He consistently sought advice and
                    utilized constructive feedback to enhance his skills,
                    showcasing a proactive approach to learning. His passion for
                    software engineering and his dedication to honing his craft
                    are qualities that will undoubtedly drive him towards a
                    successful career in the tech industry.
                    <i className="d-big icon_quotations"></i>
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="img/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Kemal Hussien Gudeta</h3>
                  <h4>Senior Software Engineer at TATA Consultancy Services</h4>
                </div>
              </div>
              {/* End testimonial item*/}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>

        <style>
          {`.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #ffffff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #c3e8fa;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #fff;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #ffffff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #149ddd;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1b0303;
}`}
        </style>
      </section>
    );
}
export default testimonials;