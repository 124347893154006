import React from 'react';

import "@fortawesome/fontawesome-free/css/all.css";





const footer = () => {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <a
                href="https://bikilanegasa.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="copy">
                  &copy; Copyright 2024 - By Bikila N. Gilo
                </span>
              </a>
            </div>
            <div className="col-md-6">
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/bikila.negasa"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://twitter.com/BikilaGilo" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/bikila-negasa-gilo-6a044825/"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.instagram.com/bikila.negasa/?hl=en"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://bikilanegasa.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-rss"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default footer;