import React from 'react';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              About Me
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="20"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <div
            className="col-md-8 text-center m-auto"
            data-aos="fade-up"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <p>
              Hello! I am a full stack web developer based in Phoenix, Arizona
              with expertise in UI/UX web development. With over 7 projects
              under my belt, I am experienced in creating visually appealing and
              user-friendly websites that cater to the needs of my clients.
              Please feel free to check out my portfolio section on this website
              to see some of my work. I am always looking for new opportunities
              and challenges to expand my skills and knowledge.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={90}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">HTML</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={85}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">CSS</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={80}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">JavaScript</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={75}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">React.js</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={80}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">Node.js & Express</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={75}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">MySQL</h6>
          </div>
          <div
            className="col-lg-2 p-5 text-center"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <AnimatedProgressProvider valueStart={0} valueEnd={80}>
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbar
                    value={value}
                    strokeWidth={3}
                    text={`${roundedValue}.0%`}
                    styles={buildStyles({
                      pathTransition: "none",
                      textColor: "white",
                      textSize: "20px",
                      pathColor: "#0bdef1",
                      trailColor: "#2f3134",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
            <h6 className="mt-2">Next.js</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default hero;