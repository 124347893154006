import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 text-center"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <h2>What I Do</h2>
            <div className="space-border"></div>
          </div>
        </div>
        <div className="spacer-single"></div>
        <div className="row">
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_genius id-color-2"></i> */}
                <div className="text">
                  <h3>Front-end Development</h3>I design and build the user
                  interface (UI) of websites and web applications using HTML,
                  CSS, and JavaScript. I ensure that the user experience is
                  seamless, responsive, and visually appealing across various
                  devices and browsers.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_tools id-color-2"></i> */}
                <div className="text">
                  <h3>Back-end Development</h3>I develop the server-side logic,
                  databases, and APIs that power the functionality of websites
                  and web applications. I use languages like PHP and Node.js to
                  handle data, user authentication, and application logic.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_camera_alt id-color-2"></i> */}
                <div className="text">
                  <h3>Web Frameworks</h3>I leverage framework like React.js for
                  front-end development and framework like Express.js for
                  back-end development. These frameworks streamline the
                  development process and provide tools for building robust
                  applications.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_genius id-color-2"></i> */}
                <div className="text">
                  <h3>Version Control and Collaboration</h3>I use version
                  control systems like Git to track changes in my codebase,
                  collaborate with team members, and manage different versions
                  of my projects. I collaborate with designers, other
                  developers, and stakeholders to bring projects to fruition,
                  ensuring that the final product aligns with the desired vision
                  and functionality.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_genius id-color-2"></i> */}
                <div className="text">
                  <h3>Database Management</h3>I design, implement, and manage
                  databases using ystems like MySQL and MongoDB. This involves
                  structuring data, ensuring data integrity, and optimizing
                  database performance.
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <div className="de_3d-box">
              <div className="d-inner">
                {/* <i className="icon_genius id-color-2"></i> */}
                <div className="text">
                  <h3>Project Management and Learning</h3>Depending
                  on my role, I have involved in project management tasks, including planning, timeline management, and communication with clients or team members. I also stay updated with the latest web development trends, technologies, and best practices to enhance my skills and contribute to innovative solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default whatido;