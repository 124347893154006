import React from "react";
import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>My Resume</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row gh-5">
        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Experiences</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Oct 2022 - now</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Self Employed &amp; Full Stack Web Developer
                  </span>
                  <span className="d_company">EG3</span>I played a multifaceted
                  and crucial role in the realm of web development, possessing
                  expertise in both front-end and back-end technologies. I
                  aadept at crafting complete web solutions, from designing user
                  interfaces and interactive experiences using HTML, CSS, and
                  JavaScript to developing server-side logic, databases, and
                  APIs with languages like Node.js or Express.js and PHP. I have
                  a comprehensive understanding of the entire web development
                  process, allowing me to seamlessly bridge the gap between
                  user-facing elements and behind-the-scenes functionality. This
                  proficiency enables me to create end-to-end applications,
                  troubleshoot issues across the stack, and optimize performance
                  for a seamless user experience. With my versatile skill sets,
                  I contribute to the creation of robust, dynamic, and efficient
                  web applications that cater to the demands of modern digital
                  environments.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Oct 2020 - Oct 2022</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Website Developer</span>
                  <span className="d_company">Ambo Microbusiness College</span>I
                  have a pivotal role in shaping the minds of future
                  technologists and innovators. I possess a profound
                  understanding of fundamental computer science concepts,
                  programming languages, algorithms, and software development
                  methodologies. On the front-end, I've skills in crafting
                  intuitive user interfaces using HTML, CSS, and JavaScript.
                  From responsive designs to interactive elements, I've ensured
                  seamless user experiences across various devices and browsers.
                  Delving into the back-end, I've delved into the intricacies of
                  server-side scripting language like Node.js, and mastered
                  frameworks such as PHP, or Express.js. My expertise extends to
                  designing robust databases, optimizing queries, and
                  implementing RESTful APIs to facilitate smooth data flow
                  between the server and client. As I continue to evolve as a
                  web developer, my journey embodies the spirit of growth,
                  adaptability, and innovation that defines this ever-evolving
                  field.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Education</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Oct 2022 - Apr 2023</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Certificate on Full Stack Web Development
                  </span>
                  <span className="d_company">Evangadi Tech</span>The
                  Certificate I had in Full Stack Web Development at Evangadi
                  Tech offers an immersive and comprehensive learning experience
                  that equiped me with the skills needed to excel in the dynamic
                  world of web development. This program covers a wide spectrum
                  of technologies, including front-end languages like HTML, CSS,
                  and JavaScript/React.js, as well as back-end frameworks like
                  MySQL, Node.js, Express.js, PHP, and databases. Students
                  engage in practical, hands-on projects that mirror real-world
                  scenarios, allowing them to master the entire web development
                  process, from designing user interfaces to building robust
                  server-side applications. The instructors at Evangadi Tech
                  bring industry expertise and guide me through the intricacies
                  of full stack web development, while fostering a collaborative
                  learning environment. Upon completion of this certificate
                  program, I emerged as adept and versatile full stack
                  developer, poised to contribute to the creation of dynamic and
                  innovative web applications.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">Oct 2016 - Aug 2020</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Bachelor of Science in Computer Science
                  </span>
                  <span className="d_company">Rift Valley University</span>My
                  Bachelor of Science in Computer Science journey at Rift Valley
                  University has been a transformative experience that has
                  enriched my understanding of the intricate world of
                  technology. Throughout the program, I have delved into a
                  diverse range of subjects, including programming languages,
                  data structures, algorithms, and software engineering
                  methodologies. The curriculum's balance between theory and
                  practical application has honed my problem-solving skills and
                  equipped me with the tools to tackle complex challenges in the
                  realm of computer science. Engaging with dedicated faculty
                  members and collaborating with fellow students has cultivated
                  a dynamic learning environment, fostering creativity and a
                  deep passion for innovation. From developing software
                  applications to analyzing intricate systems, my education at
                  Rift Valley University has not only laid a strong foundation
                  for my future career but has also ignited a perpetual
                  curiosity to explore the ever-evolving landscape of
                  technology.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="spacer-double"></div>
      </div>
    </div>
  );
};

export default hero;
