import React from 'react';
import { useState } from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= () => {
  /* lightbox1 */
  const [lighbx, setlighbx] = useState(false);
  const handleBtnClick = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox2 */
  const [lighbx1, setlighbx1] = useState(false);
  const handleBtnClick1 = () => {
    setlighbx1(!lighbx1);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose1 = () => {
    setlighbx1(!lighbx1);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox3 */
  const [lighbx2, setlighbx2] = useState(false);
  const handleBtnClick2 = () => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose2 = () => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox4 */
  const [lighbx3, setlighbx3] = useState(false);
  const handleBtnClick3 = () => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose3 = () => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox5 */
  const [lighbx4, setlighbx4] = useState(false);
  const handleBtnClick4 = () => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose4 = () => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  /* lightbox6 */
  const [lighbx5, setlighbx5] = useState(false);
  const handleBtnClick5 = () => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose5 = () => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  /* lightbox7 */
  const [lighbx6, setlighbx6] = useState(false);
  const handleBtnClick6 = () => {
    setlighbx6(!lighbx6);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose6 = () => {
    setlighbx6(!lighbx6);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  /* lightbox8 */
  const [lighbx7, setlighbx7] = useState(false);
  const handleBtnClick7 = () => {
    setlighbx7(!lighbx7);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose7 = () => {
    setlighbx7(!lighbx7);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };

  return (
    <div className="container">
      <GlobalStyles />
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Portfolio</h2>
          <div className="space-border"></div>
        </div>
      </div>

      <Masonry className={"row my-gallery-class"} elementType={"div"}>
        {/* Apple page  */}

        <div
          className="col-lg-4 image-element-class de_modal de_modal"
          onClick={handleBtnClick}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Apple page website</h3>
              <h5 className="d-tag">website</h5>
            </div>
            <img src="./img/gallery/Apple_logo.jpeg" alt="gallery" />
          </div>
        </div>

        {/* Netflix page */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick1}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Netflix website clone</h3>
              <h5 className="d-tag">website</h5>
            </div>
            <img src="./img/gallery/Netflix1.jpeg" alt="gallery" />
          </div>
        </div>

        {/* Zoom page */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick2}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Zoom website clone</h3>
              <h5 className="d-tag">website</h5>
            </div>
            <img src="./img/gallery/3.jpeg" alt="gallery" />
          </div>
        </div>

        {/* Amazon page */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick3}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Amazon website clone</h3>
              <h5 className="d-tag">website</h5>
            </div>
            <img src="./img/gallery/4.jpg" alt="gallery" />
          </div>
        </div>

        {/* iPhone calculator */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick4}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>iPhone Calculator</h3>
              <h5 className="d-tag">App</h5>
            </div>
            <img src="./img/gallery/iPhone_calculator.jpg" alt="gallery" />
          </div>
        </div>

        {/* Authentication page */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick5}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Authentication_Q&A Forum</h3>
              <h5 className="d-tag">website</h5>
            </div>
            <img src="./img/gallery/Authentication Q_A.jpg" alt="gallery" />
          </div>
        </div>

        {/* Simple calculator */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick6}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Simple calculator</h3>
              <h5 className="d-tag">App</h5>
            </div>
            <img src="./img/gallery/Simple_calculator.jpg" alt="gallery" />
          </div>
        </div>

        {/* Snake game */}

        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick7}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Snake game</h3>
              <h5 className="d-tag">App</h5>
            </div>
            <img src="./img/gallery/Snake_game.jpg" alt="gallery" />
          </div>
        </div>
      </Masonry>

      {/* lightbox1 or Applepage */}

      {lighbx && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/Apple_logo.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Apple Page Website</h3>
                  <p>
                    The Apple Page website, developed using a sophisticated tech
                    stack comprising XML, CSS, React.js, Node.js, and MySQL,
                    represents a culmination of modern web development
                    practices. This dynamic and responsive website seamlessly
                    integrates React.js, a powerful front-end library, to
                    provide a visually engaging user interface. Leveraging
                    Node.js on the back end ensures efficient server-side
                    operations and real-time interactions, offering a smooth
                    user experience. The use of MySQL as the database management
                    system underpins the site's data storage and retrieval,
                    enabling personalized and reliable content delivery. The
                    website showcases the synergy between these cutting-edge
                    technologies, resulting in a high-performance platform that
                    aligns seamlessly with Apple's renowned aesthetic and
                    functionality. Through this venture, a user-centric design
                    ethos harmonizes with robust architecture, crafting a
                    digital experience that resonates with the brand's values
                    and innovation.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2022</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://apple-replica-3b992.firebaseapp.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://apple-replica-3b992.firebaseapp.com/
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox2 or Netflixpage */}
      {lighbx1 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose1}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <div className="row g-4">
                    <div className="col-lg-12 item">
                      <img
                        src="./img/single-gallery/11.jpeg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6 item">
                      <img
                        src="./img/single-gallery/22.jpg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6 item">
                      <img
                        src="./img/single-gallery/33.jpg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-12 item">
                      <img
                        src="./img/single-gallery/pf-2-4.jpg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6 item">
                      <img
                        src="./img/single-gallery/pf-2-5.jpg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6 item">
                      <img
                        src="./img/single-gallery/pf-2-6.jpg"
                        alt="galleryimage"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 de_project-info">
                  <h3>The Netflix Website Clone</h3>
                  <p>
                    The Netflix Page website clone is a testament to the
                    convergence of contemporary web technologies, showcasing my
                    proficiency in XML, CSS, React.js, Node.js, and RESTful API
                    integration. By replicating the iconic Netflix interface,
                    I've captured the essence of the original platform's design
                    using XML and CSS, ensuring a faithful representation of its
                    visual attraction. The incorporation of React.js enriches
                    the user experience by creating a responsive and interactive
                    UI, dynamically adapting to user interactions and
                    preferences. Under the hood, Node.js orchestrates the
                    back-end, optimizing server-side operations for seamless
                    performance. The integration of a RESTful API facilitates
                    real-time data retrieval and updates, enabling the display
                    of accurate and up-to-date content recommendations. This
                    project underscores my ability to combine multiple
                    technologies to create a clone that emulates the appearance,
                    functionality, and real-time data handling of the original
                    Netflix website, showcasing a comprehensive skill set and a
                    deep understanding of modern web development practices.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://netflix-replica-156de.web.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://netflix-replica-156de.web.app/
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox3 or lightboxvideo/zoom */}
      {lighbx2 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose2}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <video className="pop" controls autoPlay loop>
                    <source src="./video/video-2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Zoom website clone</h3>
                  <p>
                    The Zoom Page website clone is a testament to my adeptness
                    in web development, crafted using HTML, CSS, JavaScript, and
                    the Express.js framework. This project replicates the core
                    functionalities of the popular video conferencing platform
                    Zoom. It boasts a well-structured HTML and CSS foundation,
                    mirroring Zoom's clean and user-friendly interface.
                    JavaScript brings life to the application, enabling
                    real-time interactions like video conferencing, chat, and
                    screen sharing. The utilization of Express.js ensures
                    efficient server-side processing, allowing seamless
                    communication between users. This clone project showcases my
                    skills in front-end and back-end development, as well as my
                    ability to create a functional web application that emulates
                    the essential features of Zoom, offering users a familiar
                    and reliable virtual meeting experience.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://zoom-clone-7b50e.web.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://zoom-clone-7b50e.web.app/
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox4 or Amazonpage */}

      {lighbx3 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose3}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/Amazon_inner.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Amazon Page Website</h3>
                  <p>
                    The Amazon page website clone I developed showcases a
                    harmonious integration of front-end and back-end
                    technologies, leveraging XML and CSS for crafting an
                    aesthetically pleasing and structurally sound user
                    interface. XML allowed for efficient data organization,
                    ensuring seamless retrieval and presentation of information.
                    CSS, on the other hand, was instrumental in refining the
                    visual aspects, offering users an engaging and visually
                    appealing browsing experience. React.js, a powerful
                    front-end library, enriched the clone's functionality by
                    enabling dynamic updates and interactive elements, elevating
                    user interactivity and navigation. Complementing this,
                    Node.js, a robust back-end framework, facilitated efficient
                    server-side operations, ensuring a smooth flow of data and
                    interactions between the front end and the database. This
                    comprehensive tech stack culminated in an Amazon page clone
                    that mirrors the original platform's look, feel, and
                    functionality, providing users with an immersive and
                    familiar online shopping experience.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://back-end1-fc9c3.web.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://back-end1-fc9c3.web.app/
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox5 or iPhone calculator */}

      {lighbx4 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose4}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/iPhone_calculator_inner.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-4 de_project-info">
                  <h3>iPhone calculator</h3>
                  <p>
                    I crafted a sleek and functional iPhone calculator app using
                    the trio of HTML, CSS, and JavaScript. This app mirrors the
                    aesthetics and functionality of the native iPhone
                    calculator, offering a user-friendly interface and smooth
                    interactions. The HTML structure provided the foundation,
                    allowing for a well-organized layout, while CSS was
                    leveraged to create the polished and visually appealing
                    design consistent with iOS design principles. The magic,
                    however, came alive with JavaScript, enabling the calculator
                    to accurately perform arithmetic operations, handle user
                    input, and display results dynamically. The end result is an
                    intuitive calculator app that seamlessly runs in the
                    browser, providing a familiar and efficient calculating
                    experience for iPhone users.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://iphone-calculator-with-javascript.netlify.app"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://iphone-calculator-with-javascript.netlify.app
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox6 or Authentication, Q&A forum */}

      {lighbx5 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose5}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/Authentication_inner.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Authentication, Question and Answer Forum</h3>
                  <p>
                    A robust authentication, question, and answer forum is
                    crafted by seamlessly integrating front-end and back-end
                    technology tools. Leveraging my expertise in HTML, CSS, and
                    JavaScript for the front end, I designed an intuitive user
                    interface that allows smooth navigation and an engaging user
                    experience. On the back end, I utilized powerful frameworks
                    and a language called Node.js to implement a secure
                    authentication system and efficient database management.
                    This dynamic platform empowers users to authenticate
                    securely, post questions, receive answers, and engage in
                    meaningful discussions. The integration of both front-end
                    and back-end technologies ensures a seamless, responsive,
                    and feature-rich forum that caters to the needs of the users
                    while promoting a vibrant community of knowledge sharing and
                    collaboration.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>Website</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://forum-front-end.pages.dev"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://forum-front-end.pages.dev
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox7 or Simple calculator */}

      {lighbx6 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose6}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/Simple_calculator_inner.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Simple calculator</h3>
                  <p>
                    A user-friendly, web-based calculator application is crafted
                    by employing just HTML and CSS that's designed for
                    simplicity and efficiency. This minimalist calculator offers
                    basic arithmetic operations, including addition,
                    subtraction, multiplication, and division, making it a handy
                    tool for quick calculations without any unnecessary frills.
                    Its clean and intuitive interface ensures that users can
                    effortlessly perform calculations right in their browser,
                    making it a valuable addition for anyone in need of a
                    straightforward and accessible calculator tool.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>App</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2023</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://simple-calculator-with-html-and-css.netlify.app/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://simple-calculator-with-html-and-css.netlify.app/
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* lightbox8 or Snake game */}

      {lighbx7 && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose7}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row g-5">
                <div className="col-lg-8">
                  <img
                    src="./img/gallery-big/Snake_game_inner.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-4 de_project-info">
                  <h3>Snake game</h3>
                  <p>
                    This captivating snake game is developed by using the
                    classic combination of HTML, CSS, and JavaScript. This
                    web-based game offers a nostalgic experience, where players
                    control a pixelated snake, maneuvering it around the grid to
                    collect food while avoiding collisions with walls and its
                    own tail. The game features smooth animations, intuitive
                    controls, and dynamic scoring, making it an engaging and
                    addictive experience for players of all ages. Whether it's a
                    quick break or a leisurely pastime, this snake game brings
                    back fond memories and provides hours of entertaining fun
                    right in the web browser.
                  </p>

                  <div className="de_project-details">
                    <div className="d-field">
                      <i className="fa fa-user-o"></i>Client:{" "}
                      <span>Evangadi</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-file-text-o"></i>Type:{" "}
                      <span>App</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-calendar-o"></i>Year:{" "}
                      <span>2022</span>
                    </div>
                    <div className="d-field">
                      <i className="fa fa-external-link"></i>Preview:{" "}
                      <span>
                        <a
                          href="https://snake-game-with-html-css-and-js.netlify.app"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://snake-game-with-html-css-and-js.netlify.app
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {/* <blockquote>
                      "Very well done theme. Versatile, extremely well coded,
                      and gorgeous. That's a great combo. Highly recommended."
                      <span>John Archi</span>
                    </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;